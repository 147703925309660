/* Dropdown */
.dropup,
.dropright,
.dropdown,
.dropleft {
    position: relative;
}
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    max-width: 10rem;
    padding: 0.2rem 0;
    margin: 0.125rem 0 0;
    font-size: 0.88rem;
    color: #022648;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 2px solid #e6e6e6;
    border-radius: 0.25rem;
}
.table-dropdown .dropdown-menu {
    min-width: 21rem;
    /* max-height: 70vh;
    overflow-y: auto; */
}
.notifications-dropdown-container .dropdown-menu {
    min-width: 20rem;
    max-width: 25rem;
}
.dropdown-menu.show {
    animation: fade-in 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@keyframes fade-in {
    0% {
        margin-top: -50px;
        visibility: hidden;
        opacity: 0;
    }
    100% {
        margin-top: 0px;
        visibility: visible;
        opacity: 1;
    }
}
.dropdown-menu-right {
    right: 0;
    left: auto;
}
.dropdown-menu-left {
    right: auto;
    left: 0;
}
.dropdown-menu[x-placement^='top'],
.dropdown-menu[x-placement^='right'],
.dropdown-menu[x-placement^='bottom'],
.dropdown-menu[x-placement^='left'] {
    right: auto;
    bottom: auto;
}
.dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef;
}
.dropdown-item {
    display: block;
    padding: 0.3rem 0.8rem;
    font-weight: 500;
    color: #000;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    font-size: 13px;
    transition: 0.3s;
    width: 100%;
    display: flex;
    justify-content: start;
}
.dropdown-item:hover,
.dropdown-item:focus {
    color: #eac435;
    text-decoration: none;
}
.dropdown-item:hover .link-hover {
    color: #eac435;
}
.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #eac435;
    pointer-events: none;
    background-color: transparent;
    cursor: not-allowed;
}
.dropdown-menu.show {
    display: block;
}
.dropdown-header {
    display: block;
    padding: 0 1.5rem, 5rem 1.5rem;
    margin-bottom: 0;
    font-size: 0.968rem;
    color: #022648;
    white-space: nowrap;
    border-bottom: 1px solid #eee;
    text-align: center;
    text-transform: capitalize;
}
.dropdown-item-text {
    display: block;
    padding: 0.4rem 1.5rem;
    color: #212529;
}
.btn-primary.dropdown-toggle {
    background-color: transparent;
    margin: 0;
    padding: 0;
    border-color: transparent;
}
.show > .btn-primary.dropdown-toggle {
    border-color: transparent;
    background-color: transparent;
}
.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent;
    background-color: transparent;
    /* border-color: transparent; */
}
.btn.dropdown-toggle:not(:disabled):not(.disabled):active,
.btn.dropdown-toggle:not(:disabled):not(.disabled).active {
    background-color: transparent;
    border-color: transparent;
}
.dropdown-container-translated .dropdown-item {
    text-align: right;
}
@media (max-width: 991.98px) {
    .dropdown-menu::before,
    .dropdown-menu::after {
        display: none;
    }
}
@media (max-width: 800px) {
    .dropdown-menu {
        width: 80%;
        left: 10%;
    }
}

@media screen and (max-width: 450px) {
    .dropdown-menu {
        min-width: 10rem;
    }
}
.image-dropdown {
    width: 5vh;
    height: 5vh;
    border-radius: 2px solid #e6e6e6;
    box-shadow: 0 0 10px #e6e6e6;
    display: flex;
    align-items: center;
    justify-content: center;
}
.image-dropdown img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 5px;
}
.table-dropdown-card-subtext {
    font-size: 12px;
}

/* Header */
.header_container {
    height: 65px;
    display: flex;
    align-items: center;
    align-content: center;
    position: relative;
    z-index: 10;
    transition: all 0.2s;
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    flex: 1;
    padding: 0 10px 0 15px;
}
.header_container.header-shadow {
    box-shadow: 0 3px 9px 0 rgba(169, 184, 200, 0.15);
    -webkit-box-shadow: 0 3px 9px 0 rgba(169, 184, 200, 0.15);
    -moz-box-shadow: 0 3px 9px 0 rgba(169, 184, 200, 0.15);
}
.header_menu {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.header_menu_item {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.3s ease, color 0.3s ease;
}
.header_menu_item i {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 3.5vh;
    height: 3.5vh;
    border-radius: 50%;
    color: #022648;
    background-color: white;
    font-size: 1rem;
    transition: 0.3s ease, color 0.3s ease;
}
.header_menu_item:hover {
    color: #fff;
}
.header_menu_item:hover i {
    color: #eac435;
}
.language_cont {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 6vh;
    height: 4vh;
    /* border-radius: 5px; */
    color: #022648;
    /* background-color: white !important; */
    font-size: 12px;
    line-height: 4vh;
    transition: 0.3s ease, color 0.3s ease;
    font-weight: 600;
}
.header_menu_item .language_cont:hover {
    color: #eac435;
    /* background-color: #022648; */
}
.widget_heading {
    font-weight: 500;
}
.widget_heading .widgetheading-greeting {
    font-size: 20px;
    color: #8fa0bd;
}
.widget_heading .widget_username {
    font-size: 19px;
    cursor: pointer;
    color: #022648;
    max-width: 40rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600 !important;
}
.widget_username-translated {
    direction: ltr;
}
@media screen and (max-width: 1025px) {
    .widget_heading .widget_username {
        max-width: 20rem;
    }
}
/* Badge */
.badge_counter_header {
    position: absolute;
    transform: scale(0.7);
    transform-origin: top right;
    margin-top: -1rem;
    background-color: red;
    color: #fff;
}
.badge_counter_header_notifications {
    position: absolute;
    transform: scale(0.3);
    transform-origin: top right;
    margin-top: -0.3rem;
    right: 0;
    background-color: red;
    color: #fff;
}
.header_container__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: width 0.2s;
    width: 100%;
}
.header_container__logo img {
    width: 80%;
}
.burgerIconRespCont {
    position: absolute;
    top: -18%;
    z-index: 1000;
}
.hamburger {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    margin: 0;
    padding: 0px;
    font-size: 30px;
    color: var(--primary);
    cursor: pointer;
}
.hamburger:focus {
    outline: none;
}
@media (max-width: 991.98px) {
    .header_container {
        justify-content: space-between;
    }
    .header_container__logo img {
        width: 66%;
    }
    .header_menu .header_menu_item .language_cont:hover {
        background: var(--secondary);
        color: white;
    }
}
@media screen and (max-width: 500px) {
    .hamburger span {
        font-size: 25px;
    }
    .header_container__logo img {
        width: 80%;
    }
}
/* logo */
.header_image_container {
    width: 6vh;
    height: 6vh;
    border: 5px solid white;
    border-radius: 10px;
    box-shadow: 0 0 10px #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
}
.header_image_container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}
/* name */
.widget_heading_sidenav {
    font-weight: 500;
    font-size: 14px;

}
.widget_heading_sidenav .widgetheading_greeting_sidenav {
    color: #8fa0bd;
}
.widget_heading_sidenav .widget_username_sidenav {
    color: #022648;
    max-width: 40rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
/* Welcome Page */
.welcome_card:hover {
    box-shadow: 0px 9px 20px rgba(75, 72, 72, 0.3);
    transition: 0.3s;
}
.welcome_card_expired {
    background: #d9d9d9;
}
.welcome_store_name {
    text-transform: capitalize;
    font-size: 17px;
    transition: 0.3s;
}
.welcome_card:hover .welcome_store_name {
    color: #eac435;
}
.welcome_store_link {
    font-size: 14px;
    color: #1a1aff;
    text-decoration: underline;
    transition: 0.3s;
    overflow-wrap: break-word;
}
.welcome_store_link:hover {
    color: var(--secondary);
}
.welcome_store_logo {
    width: 10vh;
    height: 10vh;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}
.welcome_store_logo img {
    max-width: 95%;
    max-height: 95%;
}
.welcome_store_logo_expired {
    background: grey;
}
@media screen and (max-width: 500px) {
    .welcome_store_logo {
        width: 9vh;
        height: 9vh;
    }
}
.form_control {
    display: block;
    width: 100%;
    height: 25px;
    font-size: 1rem;
    font-weight: 500;
    color: #eac435;
    background: no-repeat center bottom, center calc(100% - 1px);
    background-size: 0 100%, 100% 100%;
    background-clip: padding-box;
    padding-left: 0;
    padding-right: 0;
    font-size: 14px;
    transition: background 0s ease-out;
    border: 0;
    padding: 0 10px;
    text-transform: capitalize;
    transition: 0.3s;
}
.form_control:hover {
    box-shadow: 0px 9px 20px rgba(75, 72, 72, 0.3);
}
.welcome_modal_form_control {
    box-shadow: 0 0 10px #ccc;
    height: 38px;
    border-radius: 50px;
    border-bottom: none;
    background-size: 0;
}
.welcome_modal_form_control:focus {
    color: #495057;
    background-color: #fff;
    outline: 0;
    box-shadow: 0px 9px 20px rgba(75, 72, 72, 0.3);
}
.welcome_modal_form_control::placeholder {
    color: #eac435;
    opacity: 1;
}
.welcome_modal_form_control:focus {
    background-size: 0;
}
.welcome_modal_btn_primary {
    border-radius: 30px;
    padding: 0.5rem 0.8rem;
}

/* Side Nav */
.app_sidebar {
    position: fixed;
    height: 100%;
    width: 210px;
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    background: #f2f2f2;
    overflow-x: hidden;
    z-index: 15;
    transition: all 0.2s;
    padding-left: 10px;
    padding-bottom: 20px;
}
@media screen and (min-width:1024px) {
    .app_sidebar {
        overflow-y: hidden;
    }    
    .sidenavscroll{
        height: 95%;
        overflow-y: scroll;
    }
}
.app_sidebar_trans{
    padding-right: 10px;
    padding-left: 0;
}
.sidenavscroll::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}
.app_sidebar__heading {
    text-transform: uppercase;
    font-size: 0.8rem;
    margin: 0 0 0.4rem 0;
    font-weight: 400;
    color: rgb(172, 166, 166);
    white-space: nowrap;
    position: relative;
    text-align: center;
    letter-spacing: 1px;
}
.widget_heading_sidenav {
    font-weight: 500;
}
.widget_heading_sidenav .widgetheading_greeting_sidenav {
    font-size: 16px;
    color: #8fa0bd;
}
.widget_heading_sidenav .widget_username_sidenav {
    font-size: 14px !important;
    color: #000;
    max-width: 40rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.sidenav_image_container {
    width: 9vh;
    height: 9vh;
    border: 5px solid white;
    border-radius: 10px;
    box-shadow: 0 0 10px #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sidenav_image_container img {
    max-width: 100%;
    max-height: 100%;
}
.sidenav__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: width 0.2s;
    width: 100%;
    /* margin-bottom: 10px; */
}
.sidenav__logo img {
    width: 80%;
}
.vertical_nav_menu {
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
    transition: 0.3s ease-in-out;
}
.vertical_nav_menu li.sidebar_item {
    transition: 0.2s;
    display: flex;
    justify-content: start;
    flex-direction: row;
    align-items: center;
    padding: 15px 15px;
    color: #000;
    opacity: .5;
}
.vertical_nav_menu .sidenav_page_name {
    font-size: 12px;
    font-weight: 500;
    transition: 0.3s;
    color:#000 !important;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.vertical_nav_menu li .sidenav_icon_cont {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    transition: all 0.3s;
    position: relative;
    
}
.vertical_nav_menu i.metismenu_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    transition: 0.3s;
    opacity: 1;
    font-size: 16px;
    height: 100%
}
.vertical_nav_menu .divider {
    margin-bottom: 10px;
}
/* active */
.sidenav_active{
    background-color: white;
    border-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    opacity: 1 !important;
    color:#000
}
.vertical_nav_menu_trans .sidenav_active{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.sidenav_active .sidenav_page_name {
    color: var(--primary);
    font-weight: 600;
}
.sidenav_active i.metismenu_icon {
    opacity: 1;
    color:#000
}

/* hover */
.vertical_nav_menu li:hover  {
    opacity: .9 !important;
}
.vertical_nav_menu li.sidebar_item:hover .sidenav_page_name {
    color: var(--primary);
}
.vertical_nav_menu .sidenav_active.sidebar_item:hover i.metismenu_icon {
    /* color: white; */
}
.close_sidenav_resp_cont {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--primary);
    color: white;
    border-radius: 10px;
    font-size: 20px;
}
.close_sidenav_resp_cont:hover {
    background: var(--secondary);
}
.sidenav_uppermenu_resp.nav {
    display: flex;
    /* flex-wrap: wrap; */
    padding: 0;
    margin-bottom: 0;
    list-style: none;
    margin: 0;
}
.sidenav_uppermenu_resp .nav_link {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    cursor: pointer;
    transition: 0.3s ease, color 0.3s ease;
}
.sidenav_uppermenu_resp .nav_link i {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 3.5vh;
    height: 3.5vh;
    border-radius: 50%;
    color: #022648;
    background-color: white;
    box-shadow: 0 0 10px #ccc;
    font-size: 1rem;
    transition: 0.3s ease, color 0.3s ease;
}
.sidenav_uppermenu_resp .nav_link:hover {
    color: #fff;
}
.sidenav_uppermenu_resp .nav_link:hover i {
    color: #eac435;
}

.badge_counter_sidenav {
    position: absolute;
    transform: scale(0.7);
    transform-origin: top right;
    margin-top: -2rem;
    right: -8px;
    background-color: red;
    color: #fff;
}

@media (max-width: 991.98px) {
    .app_sidebar {
        width: 100% !important;
        transform: translateX(-100%);
        position: fixed;
        background-color: #f2f2f2;
        border-right: 2px solid #ccc;
        margin-top: 0;
        height: 90vh;
        overflow-y: scroll !important;
    }
    .app_main_translated .app_sidebar {
        transform: translateX(300px);
    }
    .app_sidebar.sidebar_mobile_open {
        transform: translateX(0);
    }
    .sidenav__logo img {
        width: 70%;
        margin-right: auto;
    }
}
@media screen and (max-width: 450px) {
    .sidenav__logo img {
        width: 100%;
    }
}
.btn_header {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 500;
    border-radius: 20px;
    color: #022648 !important;
    background-color: white !important;
    font-size: 1rem;
    transition: 0.3s ease, color 0.3s ease;
    font-size: 12px;
    line-height: 14px;
    padding: 10px;
}
.btn_header:hover {
    background-color: var(--secondary) !important;
    color: white !important;
}
